import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css, createGlobalStyle } from "styled-components"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "components/cards/ThreeColContactDetails.js";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: black;
    color: white; /* Ajusta o texto para ficar legível no fundo preto */
    margin: 0;
    font-family: 'Arial', sans-serif;
  }
`;

const Address = tw.span`leading-relaxed text-gray-400`; // Ajustado para maior contraste
const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-6 block text-gray-500`; // Ajustado para cinza claro
const Phone = tw.span`text-sm mt-0 block text-gray-500`; // Ajustado para cinza claro

export default () => {
  return (
    <>
      <GlobalStyle />
      <AnimationRevealPage>
        <Header />
        <ContactUsForm />
        <ContactDetails
          cards={[
            {
              title: "São Paulo",
              description: (
                <>
                  <Address>
                    <AddressLine>40 Gates Court</AddressLine>
                    <AddressLine>Endicott, NY 13760</AddressLine>
                  </Address>
                  <Email>email@mail.com</Email>
                  <Phone>+55 (11) 99999-9999</Phone>
                </>
              )
            },
            {
              title: "Rio de Janeiro",
              description: (
                <>
                  <Address>
                    <AddressLine>602 Annadale Drive</AddressLine>
                    <AddressLine>Dekalb, IL 60115</AddressLine>
                  </Address>
                  <Email>email@mail.com</Email>
                  <Phone>+55 (11) 99999-9999</Phone>
                </>
              )
            },
            {
              title: "Brasília",
              description: (
                <>
                  <Address>
                    <AddressLine>7713 Snake Hill Ave.</AddressLine>
                    <AddressLine>Piqua, OH 45356</AddressLine>
                  </Address>
                  <Email>email@mail.com</Email>
                  <Phone>+55 (11) 99999-9999</Phone>
                </>
              )
            }
          ]}
        />
        <Footer />
      </AnimationRevealPage>
    </>
  );
};
