import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled, { createGlobalStyle } from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: black;
    color: white; /* Ajusta o texto para ficar legível no fundo preto */
    margin: 0;
    font-family: 'Arial', sans-serif;
  }
`;

const Subheading = tw.span`uppercase tracking-wider text-sm`;

export default () => {
  return (
    <>
      {/* Estilo Global */}
      <GlobalStyle />

      {/* Página Principal */}
      <AnimationRevealPage>
        <Header />
        <MainFeature1
          subheading={<Subheading>Sobre Nossa Plataforma</Subheading>}
          heading="Somos uma plataforma inovadora de compensação de carbono."
          buttonRounded={false}
          primaryButtonText="Veja Como Funciona"
          imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
        />
        <MainFeature1
          subheading={<Subheading>Nossa Visão</Subheading>}
          heading="Buscamos transformar a compensação de carbono em algo acessível e eficiente."
          buttonRounded={false}
          primaryButtonText="Entre em Contato"
          imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
          textOnLeft={false}
        />
        <Features
          subheading={<Subheading>Nossos Valores</Subheading>}
          heading="Acreditamos em um futuro mais verde e transparente."
          description="Nosso compromisso é proporcionar uma maneira acessível e confiável de compensar as emissões de carbono, contribuindo para um mundo mais sustentável."
          cards={[
            {
              imageSrc: SupportIconImage,
              title: "Acessibilidade",
              description:
                "Oferecemos uma plataforma fácil de usar e acessível para todos, com pagamento via PIX e transferências bancárias.",
            },
            {
              imageSrc: ShieldIconImage,
              title: "Segurança",
              description:
                "Implementamos os mais altos padrões de segurança para garantir que suas transações e dados estejam protegidos.",
            },
            {
              imageSrc: CustomerLoveIconImage,
              title: "Transparência",
              description:
                "Oferecemos total transparência em todas as transações e a rastreabilidade completa dos créditos de carbono.",
            },
          ]}
          linkText=""
        />
        <TeamCardGrid subheading={<Subheading>Nossa Equipe</Subheading>} />
        <Footer />
      </AnimationRevealPage>
    </>
  );
};
