import React from "react";
import tw from "twin.macro";
import { css, createGlobalStyle } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnWithBackground.js";
import heroScreenshotImageSrc from "images/carbono.webp";
import macHeroScreenshotImageSrc from "images/passos.jpg";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: black;
    color: white; /* Ajusta o texto para ficar legível no fundo preto */
    margin: 0;
    font-family: 'Arial', sans-serif;
  }
`;

export default function App() {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <>
      {/* Adiciona estilos globais */}
      <GlobalStyle />
      
      {/* Página principal */}
      <AnimationRevealPage>
        <Hero roundedHeaderButton={true} />
        <Features
          subheading={<Subheading>Destaques</Subheading>}
          heading={
            <>
              Seu projeto <HighlightedText>tokenizado.</HighlightedText>
            </>
          }
        />
        <MainFeature
          subheading={<Subheading>Crédito de Caborno</Subheading>}
          imageSrc={heroScreenshotImageSrc}
          imageBorder={true}
          imageDecoratorBlob={true}
        />
        <FeatureWithSteps
          subheading={<Subheading>PASSOS</Subheading>}
          heading={
            <>
              Fácil de <HighlightedText>Começar.</HighlightedText>
            </>
          }
          textOnLeft={false}
          imageSrc={macHeroScreenshotImageSrc}
          imageDecoratorBlob={true}
          decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
        />
        <MainFeature2
          subheading={<Subheading>VALORES</Subheading>}
          heading={
            <>
              Nós sempre cumprimos com nossos <HighlightedText>Princípios.</HighlightedText>
            </>
          }
          imageSrc={prototypeIllustrationImageSrc}
          showDecoratorBlob={false}
          features={[
            {
              Icon: MoneyIcon,
              title: "Acessível",
              description: "Comprometidos em oferecer soluções simples e de fácil acesso para todos os usuários.",
              iconContainerCss: tw`bg-green-300 text-green-800`
            },
            {
              Icon: BriefcaseIcon,
              title: "Profissionalismo",
              description: "Nosso compromisso é fornecer serviços de alta qualidade com seriedade e ética em cada etapa.",
              iconContainerCss: tw`bg-red-300 text-red-800`
            }
          ]}
        />
        <Testimonial
          subheading={<Subheading>Depoimentos</Subheading>}
          heading={
            <>
              O que Nossos Clientes <HighlightedText>Dizem.</HighlightedText>
            </>
          }
          testimonials={[
            {
              stars: 5,
              profileImageSrc:
                "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
              heading: "Amazing User Experience",
              quote:
                "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
              customerName: "Charlotte Hale",
              customerTitle: "Director, Delos Inc."
            },
            {
              stars: 5,
              profileImageSrc:
                "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
              heading: "Love the Developer Experience and Design Principles !",
              quote:
                "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
              customerName: "Adam Cuppy",
              customerTitle: "Founder, EventsNYC"
            }
          ]}
        />
        <FAQ
          subheading={<Subheading>FAQS</Subheading>}
          heading={
            <>
              Você tem uma <HighlightedText>Dúvida ?</HighlightedText>
            </>
          }
          faqs={[
            {
              question: "O que são créditos de carbono?",
              answer:
                "Créditos de carbono representam uma tonelada de CO2 removida ou evitada da atmosfera. Eles ajudam a compensar emissões de gases de efeito estufa."
            },
            {
              question: "Como posso comprar créditos de carbono na plataforma?",
              answer:
                "Você pode comprar créditos de carbono diretamente pela nossa plataforma usando pagamento via PIX ou Criptomoedas."
            },
            {
              question: "Como funciona o resgate de tokens?",
              answer:
                "Os tokens podem ser resgatados por moeda fiat, como reais, após a venda na nossa plataforma."
            },
            {
              question: "O que é o lastro dos tokens?",
              answer:
                "O lastro é a quantidade de créditos de carbono associados aos tokens emitidos, garantindo a integridade e a transparência das transações."
            },
            {
              question: "Posso verificar a origem dos créditos de carbono que adquiro?",
              answer:
                "Sim! Oferecemos total transparência, com o histórico completo de transações e certificações dos créditos de carbono."
            },
            {
              question: "Como garantir que meus créditos de carbono estão sendo usados corretamente?",
              answer:
                "A plataforma fornece auditorias regulares, garantindo que cada crédito de carbono esteja validado e certificado de acordo com os padrões ambientais."
            }
          ]}
        />
        <GetStarted />
        <Footer />
      </AnimationRevealPage>
    </>
  );
}
